// const currentDomain = window.location.origin;
const SITE_URL = process.env.REACT_APP_API_URL
// const SITE_URL = currentDomain + "/server/"
const ZOOM_URL = "https://api.zoom.us/"

console.log("SITE_URL", SITE_URL);

export {
    SITE_URL,
    ZOOM_URL
}
